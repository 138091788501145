import * as moment from 'moment-timezone';

export const formatToLocalDate = (
    date,
    addDay = 0
) => {
    // get locale date
    let localDate = new Date(date)
    localDate.setDate(localDate.getDate() + addDay)
    // get user offset timezone
    let tzOffsetUser = localDate.getTimezoneOffset() * 60000
    // get paris offset timezone
    // @ts-ignore
    let mParis = moment(localDate).tz('Europe/Paris')
    let tzOffsetParis = mParis._offset * 60000
    
    // calc good date
    let newDate = new Date(localDate.getTime() + tzOffsetUser + tzOffsetParis)

    return newDate
}