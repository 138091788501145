<template>
  <div :class="classBlock">
    <div ref="header" :class="[classBlock + '_selector']">
      <div :class="[{ fixed: getIsFixed }]">
        Page QR code : 
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[establishement] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="setSelectedEstablishement('banyuls-sur-mer')">
              <v-list-item-title>Banyuls sur mer</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setSelectedEstablishement('ile-de-re')">
              <v-list-item-title>Ile de Ré</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <span> | </span>
        <v-btn 
          color="teal accent-4"
          @click="saveEsta()">
          Sauvegarder
        </v-btn>
      </div>
    </div>
    <div :class="classBlock + '_separator'"></div>
    <v-card>
      <v-card-title>Texte d'introduction</v-card-title>
      <v-card-text>
        <ckeditor v-if="datas[establishement]" :editor="editor" v-model="datas[establishement].welcome" :config="editorConfig"></ckeditor>
      </v-card-text>
    </v-card>
    <div :class="classBlock + '_separator'"></div>
    <v-card v-if="this.datas[this.establishement]">
      <v-card-title>Menus</v-card-title>
      <v-card-text>
        <v-expansion-panels class="mb-6">
          <v-expansion-panel v-for="(menu, m) in this.datas[this.establishement].menus" :key="m">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <h3 v-if="menu.name">{{menu.id}} - {{menu.name}} 
                <v-chip
                  v-if="menu.active"
                  class="ma-2"
                  :color="getChipColor(menu)"
                  text-color="white"
                >
                  Menu actif
                  <span v-if="menu.day !== null"> le {{weekdayLabels[menu.day]}}</span>
                  <span v-else> veuillez séléctionné un jour</span>
                </v-chip>
              </h3>
              <h3 v-else>{{menu.id}} - Nom du menu</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="menu.name"
                    label="Nom du menu"
                    required
                  />
                </v-col>
                <v-col md="2">
                  <v-checkbox
                    v-model="menu.active"
                    @change="setActiveMenu(menu, menu.active)"
                    label="Actif"
                  ></v-checkbox>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    v-model="menu.id"
                    label="Identifiant"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="menu.day" row>
                    <v-radio
                      v-for="(i, n) in weekdayLabels.length"
                      :key="n"
                      :label="weekdayLabels[n]"
                      :value="n"
                      @change="$nextTick(() => {setActiveMenu(menu, menu.active)})"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Tarifs :</h3><br>
                  <ckeditor v-if="menu" :editor="editor" v-model="menu.tarifs" :config="editorConfig"></ckeditor>
                </v-col>
              </v-row>
              <v-expansion-panels class="mb-6">
                <v-expansion-panel v-for="(gPlat, g) in menu.gPlats" :key="g">
                  <v-expansion-panel-header expand-icon="mdi-menu-down">
                    <h3 v-if="gPlat.name">{{gPlat.name}}</h3>
                    <h3 v-else>Nom du groupe - {{gPlat.id}}</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- title groupe -->
                    <v-text-field
                      v-model="gPlat.name"
                      label="Nom du groupe"
                      required
                    />
                    <!-- plats -->
                    <v-card v-for="(plat, p) in gPlat.plats" :key="p">
                      <v-card-text>
                        <v-text-field
                          v-model="plat.title"
                          label="Intitulé du plat"
                          required
                        />
                        <v-text-field
                          v-model="plat.desc"
                          label="Description du plat"
                          required
                        />
                        <v-btn
                          text
                          color="red accent-4"
                          @click="removePlat(menu, gPlat, plat)"
                        >
                          supprimer le plat
                        </v-btn>
                      </v-card-text>
                    </v-card>
                    <!-- actions -->
                    <v-btn
                      text
                      color="teal accent-4"
                      @click="createPlat(gPlat)"
                    >
                      Ajouter un plat
                    </v-btn>
                    <v-btn
                      text
                      color="red accent-4"
                      @click="removeGPlat(menu, gPlat)"
                    >
                      supprimer le groupe
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="menu.asterix"
                    label="Texte en bas du menu"
                    required
                  />
                </v-col>
              </v-row>
              <v-btn 
                text
                color="teal accent-4" 
                @click="createGPlat(menu)"
              >
                créer groupe de plat
              </v-btn>
              <v-btn 
                text
                color="red accent-4" 
                @click="removeMenu(menu)"
              >
                Supprimer le menu
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          color="teal accent-4"
          @click="createMenu()">
          Ajouter un menu
        </v-btn>
      </v-card-actions>
    </v-card>
    <div :class="classBlock + '_separator'"></div>
    <!-- <v-card v-if="this.datas[this.establishement]">
      <v-card-title>Calendrier des menus</v-card-title>
      <v-card-text>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :weekdays="weekdays"
          @change="getEvents"
          @click:event="showEvent"
        >
          <template #event="{ event }" class="blop">
            <div
              class="v-event-draggable"
              v-html="getEventContent(event)"
            ></div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title>
                <span v-if="selectedEvent.start">le {{leadingZero(selectedEvent.start.getDate())}} / {{leadingZero(selectedEvent.start.getMonth()+1)}} / {{selectedEvent.start.getFullYear()}}</span>
                <br>
                {{selectedEvent.name}}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <div v-if="selectedEvent && selectedEvent.datas">
                {{selectedEvent.datas.menuId}}
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-card-text>
    </v-card>
    <div :class="classBlock + '_separator'"></div> -->
    <v-card>
      <v-card-title>Informations</v-card-title>
      <v-card-text>
        <ckeditor v-if="datas[establishement]" :editor="editor" v-model="datas[establishement].infos" :config="editorConfig"></ckeditor>
      </v-card-text>
    </v-card>
    <div :class="classBlock + '_separator'"></div>
    <v-card v-if="this.datas[this.establishement]">
      <v-card-title>Promos</v-card-title>
      <v-card-text>
        <v-expansion-panels class="mb-6">
          <v-expansion-panel v-for="(promo, p) in this.datas[this.establishement].promos" :key="p">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <h3 v-if="promo.title">{{promo.id}} - {{promo.title}} 
                <v-chip
                  v-if="promo.visible"
                  class="ma-2"
                  color="green"
                  text-color="white"
                >
                  Visible
                </v-chip>
              </h3>
              <h3 v-else>{{promo.id}} - Titre de la promo</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="promo.title"
                    label="Titre de la promo"
                    required
                  />
                </v-col>
                <v-col md="2">
                  <v-checkbox
                    v-model="promo.visible"
                    label="Visible"
                  ></v-checkbox>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    v-model="promo.id"
                    label="Identifiant"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="promo.link"
                    label="Lien - https://exemple.com"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-file-input
                    v-if="!promo.urlPict"
                    label="Séléctionnez une image"
                    filled
                    prepend-icon="mdi-camera"
                    @change="(file) => selectFile(file, p, promo)"
                  ></v-file-input>
                  <div v-else>
                    <v-img contain aspect-ratio="1.7" :src="promo.urlPict"/>
                    <v-btn @click="removeImage(promo)">Changer l'image</v-btn>
                  </div>
                </v-col>
                <v-col>
                  <ckeditor v-if="promo" :editor="editor" v-model="promo.content" :config="editorConfig"></ckeditor>
                </v-col>
              </v-row>
              <v-btn 
                text
                color="red accent-4" 
                @click="removePromo(promo)"
              >
                Supprimer la promotion
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          color="teal accent-4"
          @click="createPromo()">
          Ajouter une promotion
        </v-btn>
      </v-card-actions>
    </v-card>
    <div :class="classBlock + '_separator'"></div>
    <div style="text-align:center">
      <v-btn 
        color="teal accent-4"
        @click="saveEsta()">
        Sauvegarder
      </v-btn>
    </div>
    <div :class="classBlock + '_qrcode'">
      <h2>Qr code de la page {{typeToLabel[establishement]}}</h2>
      <qrcode-vue id="qrcode" :value="getQrcodeUrl" :size="200" level="H" render-as="svg"></qrcode-vue>
      <a href="#" @click="downloadSVG" class="v-btn">Le télécharger</a>
    </div>
  </div>
</template> 

<script>
  // -------------------------------------------- imports dependencies
  import Vue from 'vue'
  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
  import Heading from '@ckeditor/ckeditor5-heading/src/heading';
  import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
  import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
  import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
  import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
  import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';  
  import Link from '@ckeditor/ckeditor5-link/src/link';  
  import { fetchGetHelper, ERequestType, fetchPostHelper, fetchPostMediaHelper } from '../tools/fetch/fetchHelper'
  import QrcodeVue from 'qrcode.vue'
  // import { formatToLocalDate } from '../tools/timeTools'
  // import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'QrCodePage',
    // components imported
    components: {QrcodeVue},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'QrCodePage',
      datas: {},
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
          plugins: [ Essentials, Heading, Bold, Italic, Alignment, Paragraph, Link ],
          toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'alignment', '|', 'link' ],
          alignment: {
            options: [ 'left', 'center', 'right' ]
          },
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
            ]
          }
      },
      establishement: 'banyuls-sur-mer',
      typeToLabel: {
        'banyuls-sur-mer': 'Banyuls sur mer',
        'ile-de-re': 'Ile de ré',
      },
      isFixed: false,
      /* events: [],
      type: 'month', 
      focus: '',*/
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      /* selectedEvent: {},
      selectedElement: null,
      selectedOpen: false, */
      weekdayLabels: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },
      menus: function () {
        if (this.datas[this.establishement]) return this.datas[this.establishement].menus
        else return []
      },
      promos: function () {
        if (this.datas[this.establishement]) return this.datas[this.establishement].promos
        else return []
      },
      getQrcodeUrl: function () {
        return process.env.VUE_APP_FRONT_URL + this.establishement + '/infos-sejour';
      },
      getIsFixed: function () {
        return this.isFixed
      }
    },

    // -------------------------------------------- methods
    methods: {
      createMenu: function () {
        let newid = this.datas[this.establishement].menus.length
        this.datas[this.establishement].menus.push({
          id: newid,
          name: null,
          active: false,
          day: null,
          tarifs: "<p>Entrée, plat, dessert : <strong>45€</strong><br>Entrée, plat ou plat, dessert : <strong>35€</strong><br>Plat unique : <strong>26€</strong></p>",
          asterix: "Viande bovine d'origine française",
          gPlats: []
        })
      },
      createPromo: function () {
        if (this.datas[this.establishement].promos === "") this.datas[this.establishement].promos = []
        let newid = this.datas[this.establishement].promos.length
        this.datas[this.establishement].promos.push({
          id: newid,
          title: null,
          urlPict: null,
          content: null,
          visible: false
        })
      },
      selectFile(file, index, promo) {
        this.datas[this.establishement].promosFiles[index] = {
          id: promo.id,
          file: file,
        }
      },
      removeMenu: function (menu) {
        const filtered = this.datas[this.establishement].menus.filter((m) => m.id !== menu.id);
        this.datas[this.establishement].menus = filtered
      },
      removePromo: function (promo) {
        const filtered = this.datas[this.establishement].promos.filter((p) => p.id !== promo.id);
        this.datas[this.establishement].promos = filtered
      },
      removeImage: function (promo) {
        promo.urlPict = null
      },
      createGPlat: function (menu) {
        let newid = menu.gPlats.length
        menu.gPlats.push({
          id: newid,
          name: null,
          plats: []
        })
      },
      removeGPlat: function (menu, gPlat) {
        let menuKey = Object.keys(this.datas[this.establishement].menus).find(key => this.datas[this.establishement].menus[key].id === menu.id)
        const filtered = menu.gPlats.filter((p) => p.id !== gPlat.id);
        this.datas[this.establishement].menus[menuKey].gPlats = filtered
      },
      createPlat: function (gPlat) {
        let newid = gPlat.plats.length
        gPlat.plats.push({
          id: newid,
          title: null,
          desc: null,
        })
      },
      removePlat: function (menu, gPlat, plat) {
        let menuKey = Object.keys(this.datas[this.establishement].menus).find(key => this.datas[this.establishement].menus[key].id === menu.id)
        let gPlatKey = Object.keys(this.datas[this.establishement].menus[menuKey].gPlats).find(key => this.datas[this.establishement].menus[menuKey].gPlats[key].id === gPlat.id)
        const filtered = gPlat.plats.filter((p) => p.id !== plat.id);
        this.datas[this.establishement].menus[menuKey].gPlats[gPlatKey].plats = filtered
      },
      setActiveMenu: function (menu, value) {
        const valueToSet = value
        this.datas[this.establishement].menus.forEach(m => {
          if (m.day === menu.day) m.active = false
        });
        menu.active = valueToSet
      },
      getChipColor: function (menu) {
        if (menu.day !== null) return 'green'
        else return 'red'
      },
      async saveEsta() {
        let formatedDatas = {
          location: this.establishement,
          welcome: this.datas[this.establishement].welcome,
          menus: this.datas[this.establishement].menus,
          infos: this.datas[this.establishement].infos,
          promos: this.datas[this.establishement].promos
        }
        // upload file
        if (this.datas[this.establishement].promosFiles ) {
          for(let i = 0; i < this.datas[this.establishement].promosFiles.length; i++) {
            if(this.datas[this.establishement].promosFiles[i]) {
              // get file
              let promoFile = this.datas[this.establishement].promosFiles[i]
              // build form
              let data = new FormData()
              // appen file
              data.append('files[]', promoFile.file)
              // post file
              await fetchPostMediaHelper(data).then(res => {
                // get promo by id
                let promo = this.datas[this.establishement].promos.filter(p => {
                  return p.id === promoFile.id
                })[0]
                promo.urlPict = process.env.VUE_APP_BACK_URL + '/storage/uploads' + res.assets[0].path;
              })
            }
          }
          // reinit promofiles array
          this.datas[this.establishement].promosFiles = []
        }
        // save datas
        if (this.datas[this.establishement]._id) formatedDatas._id = this.datas[this.establishement]._id
        await fetchPostHelper(ERequestType.SET_QRCODE, {data: formatedDatas}).catch(err => {
          console.error(err)
        })
      },
      async loadDatas() {
        await fetchGetHelper(ERequestType.GET_QRCODE).then(async (res) => {
          if (res.entries.length > 0) {
            res.entries.forEach(esta => {
              this.datas[esta.location].welcome = esta.welcome
              this.datas[esta.location].menus = esta.menus
              this.datas[esta.location].infos = esta.infos
              this.datas[esta.location].promos = esta.promos
              this.datas[esta.location]._id = esta._id
            })
          }
        }).catch(err => {
          console.error(err)
        })
      },
      setSelectedEstablishement (selectEsta) {
        this.establishement = selectEsta
        // this.refreshEvents()
      },
      welcomeUpdate () {},
      downloadSVG (evt) {
        const svgContent = document.getElementById("qrcode").getElementsByTagName('svg')[0].outerHTML
        const blob = new Blob([svgContent], {
          type: "image/svg+xml"
        })
        let url = window.URL.createObjectURL(blob);
        let link = evt.target.cloneNode(true);
        link.target = "_blank";
        link.download = "qrcode-"+this.establishement+".svg";
        link.href = url;
        link.style = "display:none"
        let body = document.querySelector('body')
        body.appendChild(link)
        link.click()
      },
      scrollHandler () {
        let headerO = this.$refs.header.getBoundingClientRect()
        let scrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
        if (scrollTop >= headerO.top) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      // init base datas object
      Object.entries(this.typeToLabel).forEach(([index, name]) => {
        Vue.set(this.datas, index, {
          nameEsta: name,
          idEsta: index,
          welcome: "",
          menus : [],
          infos: "",
          promos: [],
          promosFiles: []
        })
      })
      this.loadDatas()
      // events
      window.addEventListener("scroll", this.scrollHandler);
    },

    // -------------------------------------------- destroy
    beforeDestroy: function () {
      window.removeEventListener("scroll", this.scrollHandler);
    }
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'QrCodePage';
    .QrCodePage {
        // --------------------------------------------------------------------- CONFIG
        padding: 20px;
        max-width: 1024px;
        margin: auto;
        // --------------------------------------------------------------------- BLOCK
        .v-chip__content{
          span{
            margin-left:5px
          }
        }
        .fixed{
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          background-color: white;
          z-index: 999;
          padding: 20px;
        }

        // --------------------------------------------------------------------- ELEMENTS
        &_separator{
          height: 20px;
        }
        &_selector{
          text-align: center;
        }
        &_qrcode{
          text-align: center;
          margin: 20px;
          canvas{
            margin: auto;
          }
        }

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

