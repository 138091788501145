import axios from 'axios'

export const ERequestType = {
  GET_ESTAS : 'collections/get/establishment',
  GET_ORDERS : 'collections/get/orders',
  GET_INTENT_ORDERS : 'collections/get/intentOrders',
  GET_ALLOTEMENTS : 'collections/get/allotements',
  REMOVE_ALLOTEMENTS : 'collections/remove/allotements',
  GET_ACCOMODATION : 'collections/get/accommodation',
  GET_QRCODE : 'collections/get/qrcode',
  SET_QRCODE : 'collections/save/qrcode',
  SET_ALLOTEMENTS : 'collections/save/allotements',
  UPDATE_ESTA : 'collections/save/establishment',
  DELETE_ALLOTEMENTS : 'collections/remove/allotements',
}

/**
 * Axios post helper
 * @param {ERequestType} pRequest 
 * @param {Json Object} pJsonDatas 
 * @param {String} token 
 */
export const fetchPostHelper = (
  pRequest,
  pJsonDatas
) => {
  // Build request url
  let baseRequest = [
    // base url
    process.env.VUE_APP_BACK_URL,
    // base api
    'api',
    // string request after type, ex: get/establishment
    `/${pRequest}`
    // token
    // "?token=", token,
  ].join('')

  baseRequest += '?token=b58e07e420dd24f1c34dbf3288eca8'

  // Return promise
  return new Promise((resolve, reject) => {
    // await fetch
    // const response = axios.post(baseRequest, pJsonDatas)
    axios
      .post(baseRequest, pJsonDatas)
      .then((res) => {
        resolve(res.data)
      })
      // catch error on request
      .catch((error) => {
        reject(error.response)
      })
  })
}

/**
 * Axios post helper
 * @param {ERequestType} pRequest 
 * @param {Json Object} pJsonDatas 
 * @param {String} token 
 */
 export const fetchPostMediaHelper = (
  formData
) => {
  // Build request url
  let baseRequest = [
    // base url
    process.env.VUE_APP_BACK_URL,
    // base api
    'api',
    // string request after type, ex: get/establishment
    `/cockpit/addAssets`
    // token
    // "?token=", token,
  ].join('')

  baseRequest += '?token=b58e07e420dd24f1c34dbf3288eca8'

  // Return promise
  return new Promise((resolve, reject) => {
    // await fetch
    // const response = axios.post(baseRequest, pJsonDatas)
    axios
      .post(baseRequest, formData)
      .then((res) => {
        resolve(res.data)
      })
      // catch error on request
      .catch((error) => {
        reject(error.response)
      })
  })
}

/**
 * Axios get Helper
 * @param {ERequestType} pRequest 
 * @param {String} token 
 */
export const fetchGetHelper = (
  pRequest
) => {
  // Build request url
  let baseRequest = [
    // base url
    process.env.VUE_APP_BACK_URL,
    // base api
    'api',
    // string request after type, ex: get/establishment
    `/${pRequest}`
    // token
    // "?token=", token,
  ].join('')

  // if (token) { baseRequest += '?token=' + token }
  baseRequest += '?token=b58e07e420dd24f1c34dbf3288eca8'
  // Return promise
  return new Promise((resolve, reject) => {
    // await fetch
    // const response = axios.post(baseRequest, pJsonDatas)
    axios
      .get(baseRequest)
      .then((res) => {
        resolve(res.data)
      })
      // catch error on request
      .catch((error) => {
        console.log({error})
        reject(error.response)
      })
  })
}
