/* eslint-disable */
<template>
  <v-app>
    <!-- router-view with transition -->
    <v-main>
      <!-- default allotement overlay -->
      <v-overlay :value="overlayDefault">
        <v-card
          color="grey lighten-4"
          min-width="350px"
          light
        >
          <v-toolbar
            color="cyan"
            dark
          >
            <v-toolbar-title>Allotement par defaut</v-toolbar-title>
            <v-btn
              icon
              @click="overlayDefault = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text light>
            <span style="color:black">Etablissement(s) : </span> 
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[establishement] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="setSelectedEstablishement('all')">
                  <v-list-item-title>Tous</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSelectedEstablishement('banyuls-sur-mer')">
                  <v-list-item-title>Banyuls sur mer</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSelectedEstablishement('ile-de-re')">
                  <v-list-item-title>Ile de Ré</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <br>
            <v-form ref="formDefault" lazy-validation>
              <v-text-field
                v-model="defaultAllotClassic"
                :rules="needFieldRules"
                label="Allotement chambres Classic :"
                required
              ></v-text-field>
              <v-text-field
                v-model="defaultAllotAdventage"
                :rules="needFieldRules"
                label="Allotement chambres Avantage :"
                required
              ></v-text-field>
              <v-text-field
                v-model="defaultAllotFamily"
                :rules="needFieldRules"
                label="Allotement chambres Familiale :"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="overlayDefault = false"
            >
              Annuler
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="validateFormDefault()"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

      <!-- period allotement overlay -->
      <v-overlay :value="overlayPeriod">
        <v-card
          color="grey lighten-4"
          min-width="600px"
          light
        >
          <v-toolbar
            color="cyan"
            dark
          >
            <v-toolbar-title>Allotement d'une periode</v-toolbar-title>
            <v-btn
              icon
              @click="overlayPeriod = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text light>
            <span style="color:black">Etablissement(s) : </span> 
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[establishement] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="setSelectedEstablishement('all')">
                  <v-list-item-title>Tous</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSelectedEstablishement('banyuls-sur-mer')">
                  <v-list-item-title>Banyuls sur mer</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSelectedEstablishement('ile-de-re')">
                  <v-list-item-title>Ile de Ré</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <br>
            <v-date-picker v-model="datesPeriod" range light style="width:100%"></v-date-picker>
            <v-form ref="formPeriod" lazy-validation>
              <v-row>
                <v-col style="padding: 0 12px">
                  <v-checkbox
                    v-model="onlyPrice"
                    label="Seulement les tarifs"
                  ></v-checkbox>
                </v-col>
                <v-col style="padding: 0 12px">
                  <v-checkbox
                    v-model="periodDispoSoins"
                    label="Soins non disponnibles"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    v-if="!onlyPrice"
                    :rules="needFieldRules"
                    v-model="periodAllotClassic"
                    label="Allotement chambres Classic :"
                    required
                  ></v-text-field>
                </v-col>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    v-if="!onlyPrice"
                    :rules="needFieldRules"
                    v-model="periodAllotAdventage"
                    label="Allotement chambres Avantage :"
                    required
                  ></v-text-field>
                </v-col>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    v-if="!onlyPrice"
                    :rules="needFieldRules"
                    v-model="periodAllotFamily"
                    label="Allotement chambres Familiale :"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    :rules="needFieldRules"
                    v-model="periodPriceClassic"
                    label="Prix nuit chambres Classic :"
                    required
                  ></v-text-field>
                </v-col>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    :rules="needFieldRules"
                    v-model="periodPriceAdventage"
                    label="Prix nuit chambres Avantage :"
                    required
                  ></v-text-field>
                </v-col>
                <v-col style="padding: 0 12px">
                  <v-text-field
                    :rules="needFieldRules"
                    v-model="periodPriceFamily"
                    label="Prix nuit chambres Familiale :"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="overlayPeriod = false"
            >
              Annuler
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="validateFormPeriod()"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

      <!-- order tab overlay -->
      <v-overlay :value="overlayTabOrder">
        <v-card
          color="grey lighten-4"
          min-width="350px"
          light
        >
          <v-toolbar
            color="cyan"
            dark
          >
            <v-toolbar-title>Réservation :</v-toolbar-title>
            <v-btn
              icon
              @click="hideTabOrder()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text light v-if="selectOrder">
            {{selectOrder.firstName}} {{selectOrder.secondName}}
            <br>
            {{selectOrder.tel}}
            <br>
            {{selectOrder.email}}
            <br><br>
            {{selectOrder.establishmentId}}
            <br>
            <span v-if="selectOrder.startDate !== ''">
              du {{new Date(selectOrder.startDate).toLocaleDateString()}} au {{new Date(selectOrder.endDate).toLocaleDateString()}}
              <br>
              {{selectOrder.nbRooms}} Chambre(s) {{tradRoom(selectOrder.roomType)}}
              <br>
            </span>
            <span v-if="selectOrder.careType === 'liberty'">
              <strong>{{tradCare(selectOrder.careType, selectOrder.startDate)}} : </strong><br>
              <span v-for="(care, i) in selectOrder.selectCares" :key="i">
                <span style="text-decoration: underline;">• Jour {{i}} :</span><br>
                <span v-for="(careData, j) in care" :key="j">
                  - 1 Ref : {{careData}} - {{selectOrder.curistesNames[j]}}
                  <br>
                </span>
              </span>
              Adulte(s) : {{selectOrder.nbCurist}}<br>
              <span v-if="selectOrder.nbChild > 0">Enfant(s) : {{selectOrder.nbChild}}</span>
            </span>
            <span v-else>
              <strong>{{tradCare(selectOrder.careType, selectOrder.startDate)}}(s) : </strong><br>
              <span v-for="(care, i) in selectOrder.selectCares" :key="i">
                - 1 Ref : {{care}} - {{selectOrder.curistesNames[i]}}
                <br>
              </span>
              Adulte(s) : {{selectOrder.nbCurist}}<br>
              <span v-if="selectOrder.nbChild > 0">Enfant(s) : {{selectOrder.nbChild}}</span>
            </span>
            <span v-if="parseInt(selectOrder.nbCompanion) > 0">
              {{parseInt(selectOrder.nbCompanion)}} Accompagnant
              <br>
            </span>
            <span v-if="selectOrder.pension !== '' && selectOrder.startDate !== ''">
              {{tradPension(selectOrder.pension)}}
              <br>
            </span>
            <br>
            <span v-if="getSelectOrderSoinsNB > 0">
              <span v-if="selectOrder.careType === 'dayStays'">
                Nombre de journée ou demi-journée : {{getSelectOrderSoinsNB}}
              </span>
              <span v-else>
                Nombre de soins à la carte : {{getSelectOrderSoinsNB}}
              </span>
              <br>
              <span v-for="(soin, i) in selectOrder.soins" :key="i">
                - {{soin.quantity}} {{soin.selectCares[0]}} <span v-if="soin.duo">DUO</span><br>
                <p>aaa</p>
              </span>
              <br>
            </span>
            <div v-if="selectOrder.careType === 'events'">
              Produits commandées : {{getSelectOrderProductsNB}}
              <br>
              <span v-for="(product, i) in JSON.parse(selectOrder.content)" :key="i">
                - {{product.quantity}} {{ product.name }}<br>
              </span>
              <br>
            </div>
            <span v-if="getSelectOrderProductsNB > 0 && selectOrder.careType !== 'events'">
              Produits commandées : {{getSelectOrderProductsNB}}
              <br>
              <span v-for="(product, i) in selectOrder.products" :key="i">

                - {{product.quantity}} {{product.selectCares[0]}}<br>
              </span>
              <br>
            </span>
            <span v-if="parseInt(selectOrder.insurance) > 0">
              Assurance annulation - d'un montant de {{selectOrder.insurance}} €
              <br><br>
            </span>
            <span v-if="selectOrder.paidAt">{{getGlobalTotalSelectOrder}} € payé le {{new Date(selectOrder.paidAt).toLocaleDateString()}}</span>
            <span v-else>{{getGlobalTotalSelectOrder}} €</span>
            <span v-if="selectOrder.promo && selectOrder.promo.ref">
              <br>
              Promo {{selectOrder.promo.ref}} : {{selectOrder.promo.applyOn}}€ - {{selectOrder.promo.reducValue}}€ soit {{selectOrder.promo.total}}€
            </span>
            <span v-if="selectOrder.comment && selectOrder.comment !== ''">
              <br><br>
              Commentaire client : <br>
              {{selectOrder.comment}}<br>
              <br>
            </span>
            <!-- <br><br>
            <span v-if="selectOrder.bkdo">
              <a>liens vers le bon cadeau</a>
            </span> -->
            <br><br>
            Informations Stripe :
            <br>
            id : {{selectOrder.id}}<br>
            intention de paiement : {{selectOrder.intent}}<br>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="hideTabOrder()"
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

      <!-- order overlay -->
      <v-overlay :value="overlayOrder">
        <v-card
          color="grey lighten-4"
          min-width="350px"
          light
        >
          <v-toolbar
            color="cyan"
            dark
          >
            <v-toolbar-title>Réservation :</v-toolbar-title>
            <v-btn
              icon
              @click="hideOrder()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text light v-if="selectOrder">
            {{selectOrder.name}} {{selectOrder.secondName}}
            <br>
            {{selectOrder.tel}}
            <br>
            {{selectOrder.email}}
            <br><br>
            du {{selectOrder.start}} au {{selectOrder.end}}
            <br>
            {{selectOrder.nbRooms}} Chambre(s) {{selectOrder.rType}}
            <br>
            <span v-for="(care, i) in selectOrder.selectCares" :key="i">
              1 Curiste {{selectOrder.cType}} ref : {{care}} - {{selectOrder.curistesNames[i]}}
              <br>
            </span>
            <span v-if="parseInt(selectOrder.nbCompanion) > 0">
              {{parseInt(selectOrder.nbCompanion)}} Accompagnant
              <br>
            </span>
            <span v-if="selectOrder.pensionType !== ''">
              {{selectOrder.pensionType}}
              <br>
            </span>
            <br>
            <span v-if="getSelectOrderSoinsNB > 0">
              <span v-if="selectOrder.cType === 'dayStays'">
                Nombre de journée ou demi-journée : {{getSelectOrderSoinsNB}}
              </span>
              <span v-else>
                Nombre de soins à la carte : {{getSelectOrderSoinsNB}}
              </span>
              <br>
              <span v-for="(soin, i) in selectOrder.soins" :key="i">
                111- {{soin.quantity}} {{soin.selectCares[0]}} <span v-if="soin.duo">DUO</span><br>
              </span>
              <br>
            </span>
            <span v-if="parseInt(selectOrder.insurance) > 0">
              <br>
              Assurance annulation prise d'un montant de {{selectOrder.insurance}} €
              <br>
            </span>
            {{getGlobalTotalSelectOrder}} € payé le {{selectOrder.paidDate}}
            <span v-if="selectOrder.promo && selectOrder.promo.ref">
              <br>
              Promo {{selectOrder.promo.ref}} : {{selectOrder.promo.applyOn}}€ - {{selectOrder.promo.reducValue}}€ soit {{selectOrder.promo.total}}€
            </span>
            <br><br>
            Informations Stripe :
            <br>
            id : {{selectOrder.id}}<br>
            intention de paiement : {{selectOrder.intent}}<br>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="hideOrder()"
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

      <!-- Calendar -->
      <v-tabs
        v-model="tab"
        centered
        background-color="cyan"
        dark
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-0">
          Intentions
        </v-tab>

        <v-tab href="#tab-1">
          Commandes
        </v-tab>

        <v-tab href="#tab-2">
          Allotements
        </v-tab>

        <v-tab href="#tab-3">
          Page Qr Code
        </v-tab>
        <v-btn
          text
          color="secondary"
          @click="hardRefresh()"
        >
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </v-tabs>
      <div v-if="loading" class="loader">
        <div class="loader-content">
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
          Mise à jour des données
        </div>
      </div>
      <v-tabs-items v-else v-model="tab">
        <v-tab-item
          key="0"
          value="tab-0"
        >
          <v-data-table
            v-if="Object.entries(this.intentOrders).length > 0"
            dense
            :headers="this.headersIntentOrders"
            :items="this.intentOrders"
            :sort-by="['_created']"
            :sort-desc="[true]"
            :items-per-page="15"
            class="elevation-1"
            @click:row="showTabOrder"
          >
            <template v-slot:item._created="{ item }">
              {{ new Date(parseInt(item._created) * 1000).toLocaleDateString('fr-FR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
            </template>
            <template v-slot:item.firstName="{ item }">
              {{ item.firstName.toUpperCase() }}
            </template>
            <template v-slot:item.careType="{ item }">
              {{ tradCare(item.careType, item.startDate) }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ item.total }} €
            </template>
            <template v-slot:item.validated="{ item }">
              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="item.validated"
              >
                Finalisé
              </v-chip>
              <v-chip
                class="ma-2"
                color="orange"
                text-color="white"
                v-else
              >
                Non finalisé
              </v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          key="1"
          value="tab-1"
        >
          <div class="btn-centered">
            <json-excel
              v-if="Object.entries(this.mainOrders).length > 0"
              class="v-btn v-btn--contained theme--light v-size--default"
              :data="getOrdersInJson"
              :fields="getHeadersOrdersInJson"
              :worksheet="'Export des commandes du '+new Date().toLocaleDateString()"
              :name="'export_commandes_'+new Date().toLocaleDateString()+'.xls'"
            >
              Exporter toutes les commandes
            </json-excel>
          </div>
          <v-data-table
            v-if="Object.entries(this.mainOrders).length > 0"
            dense
            :headers="this.headersOrders"
            :items="this.mainOrders"
            :sort-by="['_created']"
            :sort-desc="[true]"
            :items-per-page="15"
            class="elevation-1"
            @click:row="showTabOrder"
          >
            <template v-slot:item.paidAt="{ item }">
              {{ new Date(item.paidAt).toLocaleDateString('fr-FR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
            </template>
            <template v-slot:item.firstName="{ item }">
              {{ item.firstName.toUpperCase() }}
            </template>
            <template v-slot:item.careType="{ item }">
              {{ tradCare(item.careType, item.startDate) }}
            </template>
            <template v-slot:item.startDate="{ item }">
              {{ new Date(item.startDate).toLocaleDateString() }}
            </template>
            <template v-slot:item.endDate="{ item }">
              {{ new Date(item.endDate).toLocaleDateString() }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ item.total }} €
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          key="2"
          value="tab-2"
        >
          <v-row class="fill-height">
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat color="white">
                  <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                    Aujourd'hui
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-btn @click="removeOldAllotement()">
                    clean
                  </v-btn>
                  <v-spacer></v-spacer>
                  
                  <v-btn outlined class="mr-4" color="grey darken-2" @click="overlayDefault = true">
                    Allotement par default
                  </v-btn>
                  <v-btn outlined class="mr-4" color="grey darken-2" @click="overlayPeriod = true">
                    Allotement d'une periode
                  </v-btn>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[establishement] }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="setSelectedEstablishement('all')">
                        <v-list-item-title>Tous</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="setSelectedEstablishement('banyuls-sur-mer')">
                        <v-list-item-title>Banyuls sur mer</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="setSelectedEstablishement('ile-de-re')">
                        <v-list-item-title>Ile de Ré</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="90%">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  :weekdays="weekdays"
                  @change="getEvents"
                  @click:event="showEvent"
                >
                  <template #event="{ event }">
                    <div
                      class="v-event-draggable"
                      v-html="getEventContent(event)"
                    ></div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                  >
                    <v-toolbar
                      :color="selectedEvent.color"
                      dark
                    >
                      <v-toolbar-title>
                        <span v-if="selectedEvent.start">le {{leadingZero(selectedEvent.start.getDate())}} / {{leadingZero(selectedEvent.start.getMonth()+1)}} / {{selectedEvent.start.getFullYear()}}</span>
                        <br>
                        {{selectedEvent.name}} 
                        <span v-if="selectedEvent && selectedEvent.datas">
                            : Standard : {{selectedEvent.datas.allotClassic - selectedEvent.datas.nbRoomBookedClassic}} 
                            - Avantage : {{selectedEvent.datas.allotAdventage - selectedEvent.datas.nbRoomBookedAdventage}}
                            - Familiale : {{selectedEvent.datas.allotFamily - selectedEvent.datas.nbRoomBookedFamily}}
                          </span>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <div v-if="selectedEvent && selectedEvent.datas && selectedEvent.datas.orders">
                        Réservations :
                        <v-data-table
                          :headers="tabHeaders"
                          :items="selectedEvent.datas.orders"
                          :items-per-page="5"
                          @click:row="showOrder"
                          class="elevation-1"
                        ></v-data-table>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>Disponnibilité des soins</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-form ref="formDateSoins" lazy-validation>
                              <v-row v-if="selectedEvent && selectedEvent.datas">
                                <v-col>
                                  <v-checkbox
                                    v-model="dispoDaySoins"
                                    label="Soins non disponnibles"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              
                              <v-card-actions>
                                <v-btn
                                  text
                                  color="secondary"
                                  @click="selectedOpen = false"
                                >
                                  Annuler
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="validateFormDateSoins(getEventStartDate, getEventStartDate, selectedEvent.datas.estaData,allotDayClassic, allotDayAdventage, allotDayFamily, priceDayClassic, priceDayAdventage, priceDayFamily, dispoDaySoins)"
                                >
                                  Modifier
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>Prix Chambres nuité</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-form ref="formDatePrice" lazy-validation>
                              <v-row v-if="selectedEvent && selectedEvent.datas">
                                <v-col>
                                  <div>Prix nuité</div>
                                  <v-text-field
                                    v-model="priceDayClassic"
                                    :rules="needFieldRules"
                                    label="Prix Chambres Standard 1 nuit :"
                                    type="number"
                                  />
                                  <v-text-field
                                    v-model="priceDayAdventage"
                                    :rules="needFieldRules"
                                    label="Prix Chambres Avantage 1 nuit :"
                                    type="number"
                                  />
                                  <v-text-field
                                    v-model="priceDayFamily"
                                    :rules="needFieldRules"
                                    label="Prix Chambres Familiale 1 nuit :"
                                    type="number"
                                  />
                                </v-col>
                              </v-row>
                              
                              <v-card-actions>
                                <v-btn
                                  text
                                  color="secondary"
                                  @click="selectedOpen = false"
                                >
                                  Annuler
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="validateFormDatePrice(getEventStartDate, getEventStartDate, selectedEvent.datas.estaData,allotDayClassic, allotDayAdventage, allotDayFamily, priceDayClassic, priceDayAdventage, priceDayFamily, dispoDaySoins)"
                                >
                                  Modifier
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>Chambres disponnibles</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-form ref="formDateDispo" lazy-validation>
                              <v-row v-if="selectedEvent && selectedEvent.datas">
                                <v-col>
                                  <div>Chambres disponnibles</div>
                                  <v-text-field
                                    v-model="dispoDayClassic"
                                    :rules="dispoRules"
                                    @change="calcAllot()"
                                    label="Chambres Standard Disponnibles :"
                                    type="number"
                                  />
                                  <v-text-field
                                    v-model="dispoDayAdventage"
                                    :rules="dispoRules"
                                    @change="calcAllot()"
                                    label="Chambres Avantage Disponnibles :"
                                    type="number"
                                  />
                                  <v-text-field
                                    v-model="dispoDayFamily"
                                    :rules="dispoRules"
                                    @change="calcAllot()"
                                    label="Chambres Familiale Disponnibles :"
                                    type="number"
                                  />
                                </v-col>
                              </v-row>
                              
                              <v-card-actions>
                                <v-btn
                                  text
                                  color="secondary"
                                  @click="selectedOpen = false"
                                >
                                  Annuler
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="validateFormDateDispo(getEventStartDate, getEventStartDate, selectedEvent.datas.estaData, allotDayClassic, allotDayAdventage, allotDayFamily, priceDayClassic, priceDayAdventage, priceDayFamily, dispoDaySoins)"
                                >
                                  Modifier
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>Allotements de la journée</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-form ref="formDate" lazy-validation>
                              <v-row v-if="selectedEvent && selectedEvent.datas">
                                <v-col>
                                  <div>Chambres allotées</div>
                                  <div>
                                    <v-text-field
                                      v-model="allotDayClassic"
                                      :rules="needFieldRules"
                                      label="Allotement chambres Standard :"
                                      required
                                    ></v-text-field>
                                  </div>
                                  <div>
                                    <v-text-field
                                      v-model="allotDayAdventage"
                                      :rules="needFieldRules"
                                      label="Allotement chambres Avantage :"
                                      required
                                    ></v-text-field>
                                  </div>
                                  <div>
                                    <v-text-field
                                      v-model="allotDayFamily"
                                      :rules="needFieldRules"
                                      label="Allotement chambres Familiale :"
                                      required
                                    ></v-text-field>
                                  </div>
                                </v-col>
                              </v-row>
                              
                              <v-card-actions>
                                <v-btn
                                  text
                                  color="secondary"
                                  @click="selectedOpen = false"
                                >
                                  Annuler
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="validateFormDate(getEventStartDate, getEventStartDate, selectedEvent.datas.estaData, allotDayClassic, allotDayAdventage, allotDayFamily, priceDayClassic, priceDayAdventage, priceDayFamily, dispoDaySoins)"
                                >
                                  Modifier
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          key="3"
          value="tab-3"
        >
          <qr-code-page></qr-code-page>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import { mapActions, mapGetters } from 'vuex'
  import { fetchGetHelper, ERequestType, fetchPostHelper } from './tools/fetch/fetchHelper'
  import { formatToLocalDate } from './tools/timeTools'
  import JsonExcel from "vue-json-excel"
  import QrCodePage from './components/QrCodePage.vue'
  import Vue from 'vue'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'App',
    // components imported
    components: {QrCodePage, JsonExcel},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'App',
      focus: '',
      type: 'month',
      establishement: 'banyuls-sur-mer',
      estasData: {},
      defaultAllot: {},
      defaultPriceAcco: {},
      tab: null,
      loading: false,
      typeToLabel: {
        all: 'Tous',
        'banyuls-sur-mer': 'Banyuls sur mer',
        'ile-de-re': 'Ile de ré',
      },
      needFieldRules: [v => !!v || 'Merci de renseigner une valeur'],
      dispoRules: [
        v => v >= 0 || "Il y a plus de réservation que l'allotement désiré."
      ],
      datesPeriod: [],
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      allotDayClassic: 0,
      allotDayAdventage: 0,
      allotDayFamily: 0,
      priceDayClassic: 0,
      priceDayAdventage: 0,
      priceDayFamily: 0,
      dispoDayClassic: 0,
      dispoDayAdventage: 0,
      dispoDayFamily: 0,
      dispoDaySoins: false,
      events: [],
      colors: ['cyan', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      overlayPeriod: false,
      periodAllotAdventage: null,
      periodAllotFamily: null,
      periodAllotClassic: null,
      periodPriceAdventage: null,
      periodPriceFamily: null,
      periodPriceClassic: null,
      periodDispoSoins: false,
      overlayDefault: false,
      overlayOrder: false,
      overlayTabOrder: false,
      defaultAllotAdventage: 0,
      defaultAllotFamily: 0,
      defaultAllotClassic: 0,
      orders: {},
      mainOrders: {},
      intentOrders: {},
      selectOrder: null,
      onlyPrice: false,
      tabHeaders: [
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Curiste(s)', value: 'nbCurist' },
        { text: 'Accompagnant(s)', value: 'nbCompanion' },
        { text: 'Chambre(s)', value: 'nbRooms' },
        { text: 'Type de chambre', value: 'rType' },
        { text: 'Arrivé', value: 'start' },
        { text: 'Départ', value: 'end' },
      ],
      headersOrders: [
        {
          text: 'Date de la commande',
          align: 'start',
          sortable: true,
          value: 'paidAt',
        },
        { text: 'Etablissement', value: 'establishmentId' },
        { text: 'Nom', value: 'firstName' },
        { text: 'Type', value: 'careType' },
        { text: 'Arrivé', value: 'startDate' },
        { text: 'Départ', value: 'endDate' },
        { text: 'Prix', value: 'total' },
      ],
      headersIntentOrders: [
        {
          text: 'Date de la commande',
          align: 'start',
          sortable: true,
          value: '_created',
        },
        { text: 'Etablissement', value: 'establishmentId' },
        { text: 'Nom', value: 'firstName' },
        { text: 'Type', value: 'careType' },
        { text: 'Prix', value: 'total' },
        { text: 'Etat', value: 'validated' }
      ]
    }),

    // -------------------------------------------- computed properties
    computed: {
      ...mapGetters({
        getStoresIsLoaded: 'getStoresIsLoaded'
      }),
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },
      getSelectOrderSoinsNB: function() {
        let count = 0
        if (this.selectOrder.soins && this.selectOrder.soins.length > 0) {
          this.selectOrder.soins.forEach(soin => {
            count += soin.quantity
          })
        }
        return count
      },
      getSelectOrderProductsNB: function() {
        let count = 0
        if (this.selectOrder.products && this.selectOrder.products.length > 0) {
          this.selectOrder.products.forEach(product => {
            count += product.quantity
          })
        }
        return count
      },
      getGlobalTotalSelectOrder: function() {
        let total = parseFloat(this.selectOrder.total)
        return total
      },
      getEventStartDate: function () {
        return this.selectedEvent.start.getFullYear() + '-' + ('0' + (this.selectedEvent.start.getMonth() + 1)).slice(-2) + '-' + ('0' + this.selectedEvent.start.getDate()).slice(-2)
      },
      getOrdersInJson: function() {
        let datasToExport = []
        Object.entries(this.mainOrders).forEach(el => {
          let order = el[1]
          let data = {
            date: new Date(order.paidAt),
            paidAt: new Date(order.paidAt).toLocaleDateString(),
            establishmentId: order.establishmentId,
            firstName: order.firstName,
            careType: this.tradCare(order.careType, order.startDate),
            startDate: new Date(order.startDate).toLocaleDateString(),
            endDate: new Date(order.endDate).toLocaleDateString(),
            total: order.total.toString().replace('.',',')
          }
          datasToExport.push(data)
        })
        datasToExport.sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        });
        return datasToExport
      },
      getHeadersOrdersInJson: function() {
        return {
          "Date de la commande": "paidAt",
          "Etablissement": "establishmentId",
          Nom: "firstName",
          Type: "careType",
          "Arrivé": "startDate",
          "Départ": "endDate",
          Prix: "total",
        }
      }
    },

    // -------------------------------------------- methods
    methods: {
      ...mapActions({
        // initStores: 'initStores'
      }),
      hardRefresh () {
        location.reload(true);
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      tradRoom (room) {
        switch(room) {
          case '' : return 'Pas de chambre'
          case 'classic' : return 'Standard'
          case 'adventage' : return 'Avantage'
          case 'family' : return 'Familiale'
        }
      },
      tradPension (pension) {
        switch(pension) {
          case '' : return 'Pas de pension'
          case 'half' : return 'Demi-pension'
          case 'full' : return 'Pension complète'
          case 'breakfast' : return 'Petit déjeuner'
        }
      },
      tradCare (care, date) {
        switch(care) {
          case 'cures' : if (date === '') {return 'Cure Externe'} else {return 'Cure'}
          case 'accommodation' : return 'Hébergements'
          case 'soins' : return 'Soins'
          case 'liberty' : return 'Formule liberté'
          case 'stays' : return 'Escale'
          case 'cadeaux' : return 'Coffret cadeaux'
          case 'dayStays' : return 'Journée ou Demi-journée'
          case 'events' : return 'Événement'
        }
      },
      getEventColor (event) {
        return event.color
      },
      getEventContent: function (event) {
        let content = ''
        content += event.name + '<br>'
        let dispoClassic = parseInt(event.datas.allotClassic) - parseInt(event.datas.nbRoomBookedClassic)
        let dispoAdventage = parseInt(event.datas.allotAdventage) - parseInt(event.datas.nbRoomBookedAdventage)
        let dispoFamily = parseInt(event.datas.allotFamily) - parseInt(event.datas.nbRoomBookedFamily)
        content += 'Standard : ' + dispoClassic + ' - ' +  event.datas.priceClassic + ' €</br>'
        content += 'Avantage : ' + dispoAdventage + ' - ' +  event.datas.priceAdventage + ' €</br>'
        content += 'Familiale : ' + dispoFamily + ' - ' +  event.datas.priceFamily + ' €</br>'
        if (event.datas.soins) {
          content += 'Pas de soins'
        }
        return content
      },
      getEventPrice (event) {},
      showOrder (selectOrder) {
        this.selectedOpen = false
        this.selectOrder = selectOrder
        this.overlayOrder = true
      },
      hideOrder () {
        this.overlayOrder = false
        this.selectedOpen = true
      },
      showTabOrder (selectOrder) {
        this.selectOrder = selectOrder
        this.overlayTabOrder = true
      },
      hideTabOrder () {
        this.overlayTabOrder = false
      },
      showEvent ({ nativeEvent, event }) {     
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
          this.allotDayClassic = this.selectedEvent.datas.allotClassic
          this.allotDayAdventage = this.selectedEvent.datas.allotAdventage
          this.allotDayFamily = this.selectedEvent.datas.allotFamily
          this.priceDayClassic = this.selectedEvent.datas.priceClassic
          this.dispoDaySoins = this.selectedEvent.datas.soins
          this.priceDayAdventage = this.selectedEvent.datas.priceAdventage
          this.priceDayFamily = this.selectedEvent.datas.priceFamily
          this.dispoDayClassic = parseInt(this.allotDayClassic) - parseInt(this.selectedEvent.datas.nbRoomBookedClassic)
          this.dispoDayAdventage = parseInt(this.allotDayAdventage) - parseInt(this.selectedEvent.datas.nbRoomBookedAdventage)
          this.dispoDayFamily = parseInt(this.allotDayFamily) - parseInt(this.selectedEvent.datas.nbRoomBookedFamily)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      validateFormDate (start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins) {
        if (this.$refs.formDate.validate()) {
          this.updateAllotementsDatas(start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins)
        }
      },
      validateFormDateDispo (start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins) {
        if (this.$refs.formDateDispo.validate()) {
          this.calcAllot()
          this.updateAllotementsDatas(start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins)
        }
      },
      validateFormDatePrice (start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins) {
        if (this.$refs.formDatePrice.validate()) {
          this.calcAllot()
          this.updateAllotementsDatas(start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins)
        }
      },
      validateFormDateSoins (start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins) {
        if (this.$refs.formDateSoins.validate()) {
          this.calcAllot()
          this.updateAllotementsDatas(start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins)
        }
      },
      validateFormPeriod () {
        if (this.$refs.formPeriod.validate()) {
          this.updatePeriodAllotements()
        }
      },
      validateFormDefault () {
        if (this.$refs.formDefault.validate()) {
          this.updateDefaultAllotements()
        }
      },
      validDispoMin (value) {
        if (parseInt(value) < 0) {
          return false;
        } else {
          return true;
        }
      },
      calcAllot () {
        if (!this.dispoDayClassic || this.dispoDayClassic === '' || this.dispoDayClassic === ' ') this.dispoDayClassic = 0
        if (!this.dispoDayAdventage || this.dispoDayAdventage === '' || this.dispoDayAdventage === ' ') this.dispoDayAdventage = 0
        if (!this.dispoDayFamily || this.dispoDayFamily === '' || this.dispoDayFamily === ' ') this.dispoDayFamily = 0
        
        if (parseInt(this.dispoDayClassic) >= 0) {
          this.allotDayClassic = parseInt(this.dispoDayClassic) + parseInt(this.selectedEvent.datas.nbRoomBookedClassic)
        }
        if (parseInt(this.dispoDayAdventage) >= 0) {
          this.allotDayAdventage = parseInt(this.dispoDayAdventage) + parseInt(this.selectedEvent.datas.nbRoomBookedAdventage)
        }
        if (parseInt(this.dispoDayFamily) >= 0) {
          this.allotDayFamily = parseInt(this.dispoDayFamily) + parseInt(this.selectedEvent.datas.nbRoomBookedFamily)
        }
      },
      async updateDefaultAllotements () {
         if (this.establishement === 'all') {
          for(let i=0; i < Object.entries(this.estasData).length; i++) {
            let tmpEsta = Object.entries(this.estasData)[i]
            tmpEsta.defaultAllotementClassic = this.defaultAllotClassic
            tmpEsta.defaultAllotementAdventage = this.defaultAllotAdventage
            tmpEsta.defaultAllotementFamily = this.defaultAllotFamily
            await fetchPostHelper(ERequestType.UPDATE_ESTA, {data: tmpEsta}).catch(err => {
              console.error(err)
            })
          }
        } else {
          let tmpEsta = this.estasData[this.establishement]
          tmpEsta.defaultAllotementClassic = this.defaultAllotClassic
          tmpEsta.defaultAllotementAdventage = this.defaultAllotAdventage
          tmpEsta.defaultAllotementFamily = this.defaultAllotFamily
          await fetchPostHelper(ERequestType.UPDATE_ESTA, {data: tmpEsta}).catch(err => {
            console.error(err)
          })
        }
        await this.refreshEstablishementsDatas()
        this.overlayDefault = false
      },
      updatePeriodAllotements () {
        if (this.datesPeriod.length === 2) {
          if (this.establishement === 'all') {
            Object.entries(this.estasData).forEach(([id,esta]) => {
              this.updateAllotementsDatas(this.datesPeriod[0], this.datesPeriod[1], esta, this.periodAllotClassic, this.periodAllotAdventage, this.periodAllotFamily, this.periodPriceClassic, this.periodPriceAdventage, this.periodPriceFamily, this.periodDispoSoins)
            })
          } else {
            this.updateAllotementsDatas(this.datesPeriod[0], this.datesPeriod[1], this.estasData[this.establishement], this.periodAllotClassic, this.periodAllotAdventage, this.periodAllotFamily, this.periodPriceClassic, this.periodPriceAdventage, this.periodPriceFamily, this.periodDispoSoins)
          }
          this.overlayPeriod = false
        } else if(this.datesPeriod.length === 1) {
          if (this.establishement === 'all') {
            Object.entries(this.estasData).forEach(([id,esta]) => {
              this.updateAllotementsDatas(this.datesPeriod[0], this.datesPeriod[0], esta, this.periodAllotClassic, this.periodAllotAdventage, this.periodAllotFamily, this.periodPriceClassic, this.periodPriceAdventage, this.periodPriceFamily, this.periodDispoSoins)
            })
          } else {
            this.updateAllotementsDatas(this.datesPeriod[0], this.datesPeriod[0], this.estasData[this.establishement], this.periodAllotClassic, this.periodAllotAdventage, this.periodAllotFamily, this.periodPriceClassic, this.periodPriceAdventage, this.periodPriceFamily, this.periodDispoSoins)
          }
          this.overlayPeriod = false
        }
      },
      checkDisponnibilty (estaid, tsStart, tsEnd, classic, adventage, family) {
        let dispo = true
        const min = formatToLocalDate(tsStart)
        min.setHours(0,0,0,0)
        const max = formatToLocalDate(tsEnd)
        max.setHours(23,59,59,59)
        let days = (max.getTime() - min.getTime()) / 86400000
        for (let i = 0; i < Math.round(days); i++) {
          const ts = min.getTime() + (86400000 * i)
          let nbRoomBookedClassic = 0
          let nbRoomBookedAdventage = 0
          let nbRoomBookedFamily = 0
          if (this.orders[estaid] && this.orders[estaid][ts]) {
            this.orders[estaid][ts].forEach(order => {
              if (order.roomType === 'classic') {
                nbRoomBookedClassic += parseInt(order.nbRooms)
              } else if(order.roomType === 'family') {
                nbRoomBookedFamily += parseInt(order.nbRooms)
              } else {
                nbRoomBookedAdventage += parseInt(order.nbRooms)
              }
            })
          }
          if (nbRoomBookedClassic > parseInt(classic) || nbRoomBookedAdventage > parseInt(adventage) || nbRoomBookedFamily > parseInt(family)) dispo = false
        }
        return dispo
      },
      async updateAllotementsDatas (start, end, esta, classic, adventage, family, priceClassic, priceAdventage, priceFamily, soins) {
        let newAllotStart = formatToLocalDate(`${start}T00:00:00`).getTime()
        let newAllotEnd = formatToLocalDate(`${end}T23:59:59`).getTime()
        let updatedAllots = []
        let createdAllots = []

        // check order dates
        if (newAllotStart > newAllotEnd) {
          let saveEnd = newAllotEnd
          newAllotEnd = newAllotStart
          newAllotStart = saveEnd
        }

        // init base values
        let newAllotClassic = classic
        let newAllotAdventage = adventage
        let newAllotFamily = family
        let newPriceClassic = priceClassic
        let newSoins = soins
        let newPriceAdventage = priceAdventage
        let newPriceFamily = priceFamily
        if (this.onlyPrice) {
          newAllotClassic = this.estasData[esta.id].defaultAllotementClassic
          newAllotAdventage = this.estasData[esta.id].defaultAllotementAdventage
          newAllotFamily = this.estasData[esta.id].defaultAllotementFamily
        }
        // check disponibilty for allotements
        if(this.checkDisponnibilty(esta.id, newAllotStart, newAllotEnd, classic, adventage, family)) {
          await fetchGetHelper(ERequestType.GET_ALLOTEMENTS).then(async (res) => {
            // research conflict in exsting allotements
            for (let i = 0; i < res.entries.length; i++) {
              const allot = res.entries[i]
              
              const allotStart = formatToLocalDate(`${allot.start}T00:00:00`).getTime()
              const allotEnd = formatToLocalDate(`${allot.end}T23:59:59`).getTime()
              
              if(
                (newAllotStart >= allotStart && newAllotStart <= allotEnd || 
                newAllotEnd >= allotStart && newAllotEnd <= allotEnd ||
                newAllotStart <= allotStart && newAllotEnd >= allotEnd) &&
                allot.location._id === esta._id
              ) {
                if (this.onlyPrice) {
                  newAllotClassic = allot.classic
                  newAllotAdventage = allot.adventage
                  newAllotFamily = allot.family
                }
                // TODO: if conflicted allotements resolve it
                if (newAllotStart <= allotStart && newAllotEnd >= allotEnd) {
                  // replace the current allot
                  if (this.onlyPrice) {
                    const newStartDate = formatToLocalDate(allotStart)
                    const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                    const newEndDate = formatToLocalDate(allotEnd)
                    const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                    const newAllot = {
                      location: {
                        display: esta.name,
                        link: 'establishment',
                        _id: esta._id,
                      },
                      start: newStart,
                      end: newEnd,
                      adventage: newAllotAdventage,
                      family: newAllotFamily,
                      classic: newAllotClassic,
                      priceAdventage: parseInt(newPriceAdventage),
                      priceFamily: parseInt(newPriceFamily),
                      priceClassic: parseInt(newPriceClassic),
                      soins: newSoins
                    }
                    createdAllots.push(newAllot)
                  }
                } else if (newAllotStart >= allotStart && newAllotEnd <= allotEnd) {
                  // push first one
                  const firstStartDate = formatToLocalDate(allotStart)
                  const firstStart = firstStartDate.getFullYear()+'-'+this.leadingZero(firstStartDate.getMonth()+1)+'-'+this.leadingZero(firstStartDate.getDate())
                  const firstEndDate = formatToLocalDate(newAllotStart - 86400000)
                  const firstEnd = firstEndDate.getFullYear()+'-'+this.leadingZero(firstEndDate.getMonth()+1)+'-'+this.leadingZero(firstEndDate.getDate())
                  updatedAllots.push(
                    {
                      location: allot.location,
                      start: firstStart,
                      end: firstEnd,
                      adventage: allot.adventage,
                      family: allot.family,
                      classic: allot.classic,
                      priceAdventage: allot.priceAdventage,
                      priceFamily: allot.priceFamily,
                      priceClassic: allot.priceClassic,
                      soins: allot.soins
                    }
                  )
                  // push last one
                  const secondStartDate = formatToLocalDate(newAllotEnd + 86400000)
                  const secondStart = secondStartDate.getFullYear()+'-'+this.leadingZero(secondStartDate.getMonth()+1)+'-'+this.leadingZero(secondStartDate.getDate())
                  const secondEndDate = formatToLocalDate(allotEnd)
                  const secondEnd = secondEndDate.getFullYear()+'-'+this.leadingZero(secondEndDate.getMonth()+1)+'-'+this.leadingZero(secondEndDate.getDate())
                  updatedAllots.push(
                    {
                      location: allot.location,
                      start: secondStart,
                      end: secondEnd,
                      adventage: allot.adventage,
                      family: allot.family,
                      classic: allot.classic,
                      priceAdventage: allot.priceAdventage,
                      priceFamily: allot.priceFamily,
                      priceClassic: allot.priceClassic,
                      soins: allot.soins
                    }
                  )
                  if (this.onlyPrice) {
                    // push in middle the created tab
                    const newStartDate = formatToLocalDate(newAllotStart)
                    const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                    const newEndDate = formatToLocalDate(newAllotEnd)
                    const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                    const newAllot = {
                      location: {
                        display: esta.name,
                        link: 'establishment',
                        _id: esta._id,
                      },
                      start: newStart,
                      end: newEnd,
                      adventage: newAllotAdventage,
                      family: newAllotFamily,
                      classic: newAllotClassic,
                      priceAdventage: parseInt(newPriceAdventage),
                      priceFamily: parseInt(newPriceFamily),
                      priceClassic: parseInt(newPriceClassic),
                      soins: newSoins
                    }
                    createdAllots.push(newAllot)
                  }
                } else if (newAllotStart <= allotStart) {
                  // push last one
                  const secondStartDate = formatToLocalDate(newAllotEnd + 86400000)
                  const secondStart = secondStartDate.getFullYear()+'-'+this.leadingZero(secondStartDate.getMonth()+1)+'-'+this.leadingZero(secondStartDate.getDate())
                  const secondEndDate = formatToLocalDate(allotEnd)
                  const secondEnd = secondEndDate.getFullYear()+'-'+this.leadingZero(secondEndDate.getMonth()+1)+'-'+this.leadingZero(secondEndDate.getDate())
                  updatedAllots.push(
                    {
                      location: allot.location,
                      start: secondStart,
                      end: secondEnd,
                      adventage: allot.adventage,
                      family: allot.family,
                      classic: allot.classic,
                      priceAdventage: allot.priceAdventage,
                      priceFamily: allot.priceFamily,
                      priceClassic: allot.priceClassic,
                      soins: allot.soins
                    }
                  )
                  if (this.onlyPrice) {
                    // replace the start of allot
                    const newStartDate = formatToLocalDate(allotStart)
                    const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                    const newEndDate = formatToLocalDate(newAllotEnd)
                    const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                    const newAllot = {
                      location: {
                        display: esta.name,
                        link: 'establishment',
                        _id: esta._id,
                      },
                      start: newStart,
                      end: newEnd,
                      adventage: newAllotAdventage,
                      family: newAllotFamily,
                      classic: newAllotClassic,
                      priceAdventage: parseInt(newPriceAdventage),
                      priceFamily: parseInt(newPriceFamily),
                      priceClassic: parseInt(newPriceClassic),
                      soins: newSoins
                    }
                    createdAllots.push(newAllot)
                  }
                } else {
                  // push first one
                  const firstStartDate = formatToLocalDate(allotStart)
                  const firstStart = firstStartDate.getFullYear()+'-'+this.leadingZero(firstStartDate.getMonth()+1)+'-'+this.leadingZero(firstStartDate.getDate())
                  const firstEndDate = formatToLocalDate(newAllotStart - 86400000)
                  const firstEnd = firstEndDate.getFullYear()+'-'+this.leadingZero(firstEndDate.getMonth()+1)+'-'+this.leadingZero(firstEndDate.getDate())
                  updatedAllots.push(
                    {
                      location: allot.location,
                      start: firstStart,
                      end: firstEnd,
                      adventage: allot.adventage,
                      family: allot.family,
                      classic: allot.classic,
                      priceAdventage: allot.priceAdventage,
                      priceFamily: allot.priceFamily,
                      priceClassic: allot.priceClassic,
                      soins: allot.soins
                    }
                  )
                  if (this.onlyPrice) {
                    // replace the end of allot
                    const newStartDate = formatToLocalDate(newAllotStart)
                    const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                    const newEndDate = formatToLocalDate(allotEnd)
                    const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                    const newAllot = {
                      location: {
                        display: esta.name,
                        link: 'establishment',
                        _id: esta._id,
                      },
                      start: newStart,
                      end: newEnd,
                      adventage: newAllotAdventage,
                      family: newAllotFamily,
                      classic: newAllotClassic,
                      priceAdventage: parseInt(newPriceAdventage),
                      priceFamily: parseInt(newPriceFamily),
                      priceClassic: parseInt(newPriceClassic),
                      soins: newSoins
                    }
                    createdAllots.push(newAllot)
                  }
                }
                // await delete conflicted allotement
                await fetchPostHelper(ERequestType.DELETE_ALLOTEMENTS, {filter: {_id: allot._id}}).catch(err => {
                  console.error(err)
                })
              }
            }
            // TODO: if conflicted allotements create it
            if (updatedAllots.length > 0) {
              for (let i = 0; i < updatedAllots.length; i++) {
                let reCreatedAllot = updatedAllots[i]
                await fetchPostHelper(ERequestType.SET_ALLOTEMENTS, {data: reCreatedAllot}).catch(err => {
                  console.error(err)
                })
              }
            }
            if (this.onlyPrice && createdAllots.length > 0) {
              // reorder tab by first date
              createdAllots.sort(function(a, b) {
                let startA = formatToLocalDate(a.start)
                let startB = formatToLocalDate(b.start)
                return startA - startB
              })

              let tmpAllots = []

              // check before
              let startCheck = formatToLocalDate(`${createdAllots[0].start}T00:00:00`).getTime()
              if (newAllotStart < startCheck) {
                const newStartDate = formatToLocalDate(newAllotStart)
                const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                const newEndDate = formatToLocalDate(startCheck - 86400000)
                const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                const newAllot = {
                  location: {
                    display: esta.name,
                    link: 'establishment',
                    _id: esta._id,
                  },
                  start: newStart,
                  end: newEnd,
                  adventage: this.estasData[esta.id].defaultAllotementAdventage,
                  family: this.estasData[esta.id].defaultAllotementFamily,
                  classic: this.estasData[esta.id].defaultAllotementClassic,
                  priceAdventage: parseInt(newPriceAdventage),
                  priceFamily: parseInt(newPriceFamily),
                  priceClassic: parseInt(newPriceClassic),
                  soins: newSoins
                }
                // push in tmp tab
                tmpAllots.push(newAllot)
              }

              // check if we have space between date
              for (let j = 0; j < createdAllots.length - 1; j++) {
                let endi = formatToLocalDate(`${createdAllots[j].end}T23:59:59`).getTime()
                let startNexti = formatToLocalDate(`${createdAllots[j + 1].start}T00:00:00`).getTime()
                // if i.endate + 86400000 !== (i+1).startdate
                if ((endi + 86400000) < startNexti) {
                  // create new allot with defaultAllot start: i.endate + 86400000, end : (i+1).startdate - 86400000
                  const newStartDate = formatToLocalDate(endi + 86400000)
                  const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                  const newEndDate = formatToLocalDate(startNexti - 86400000)
                  const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                  const newAllot = {
                    location: {
                      display: esta.name,
                      link: 'establishment',
                      _id: esta._id,
                    },
                    start: newStart,
                    end: newEnd,
                    adventage: this.estasData[esta.id].defaultAllotementAdventage,
                    family: this.estasData[esta.id].defaultAllotementFamily,
                    classic: this.estasData[esta.id].defaultAllotementClassic,
                    priceAdventage: parseInt(newPriceAdventage),
                    priceFamily: parseInt(newPriceFamily),
                    priceClassic: parseInt(newPriceClassic),
                    soins: newSoins
                  }
                  // push in tmp tab
                  tmpAllots.push(newAllot)
                }
              }
              // check after
              let endCheck = formatToLocalDate(`${createdAllots[createdAllots.length - 1].end}T00:00:00`).getTime()
              if (newAllotEnd > endCheck) {
                const newStartDate = formatToLocalDate(endCheck + 86400000)
                const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
                const newEndDate = formatToLocalDate(newAllotEnd)
                const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
                const newAllot = {
                  location: {
                    display: esta.name,
                    link: 'establishment',
                    _id: esta._id,
                  },
                  start: newStart,
                  end: newEnd,
                  adventage: this.estasData[esta.id].defaultAllotementAdventage,
                  family: this.estasData[esta.id].defaultAllotementFamily,
                  classic: this.estasData[esta.id].defaultAllotementClassic,
                  priceAdventage: parseInt(newPriceAdventage),
                  priceFamily: parseInt(newPriceFamily),
                  priceClassic: parseInt(newPriceClassic),
                  soins: newSoins
                }
                // push in tmp tab
                tmpAllots.push(newAllot)
              }

              // push tmp tab in ths.createdAllots
              for (let j = 0; j < tmpAllots.length; j++) {
                createdAllots.push(tmpAllots[j])
              }
            } else {
              // TODO: create new allotements in db
              const newStartDate = formatToLocalDate(newAllotStart)
              const newStart = newStartDate.getFullYear()+'-'+this.leadingZero(newStartDate.getMonth()+1)+'-'+this.leadingZero(newStartDate.getDate())
              const newEndDate = formatToLocalDate(newAllotEnd)
              const newEnd = newEndDate.getFullYear()+'-'+this.leadingZero(newEndDate.getMonth()+1)+'-'+this.leadingZero(newEndDate.getDate())
              const newAllot = {
                location: {
                  display: esta.name,
                  link: 'establishment',
                  _id: esta._id,
                },
                start: newStart,
                end: newEnd,
                adventage: newAllotAdventage,
                family: newAllotFamily,
                classic: newAllotClassic,
                priceAdventage: parseInt(newPriceAdventage),
                priceFamily: parseInt(newPriceFamily),
                priceClassic: parseInt(newPriceClassic),
                soins: newSoins
              }
              createdAllots.push(newAllot)
            }
            if (createdAllots.length > 0) {
              for (let i = 0; i < createdAllots.length; i++) {
                let createdAllot = createdAllots[i]
                await fetchPostHelper(ERequestType.SET_ALLOTEMENTS, {data: createdAllot}).catch(err => {
                  console.error(err)
                })
              }
            }
            // TODO: refresh events
            this.refreshEvents()
            this.selectedOpen = false
          })
        } else {
          alert("ATTENTION : La modification ne s'est pas réalisé. Il y a plus de réservation que l'allotement désiré.")
        }
      },
      leadingZero (value) {
        return ('0' + value).slice(-2)
      },
      convertDateSeasons(date, forcedYear) {
        let [day, month, year] = date.split("-");
        if (year) return `${forcedYear}-${month}-${day}`;
        return `${forcedYear}-${month}-${day}`;
      },
      async removeOldAllotement () {
        await fetchGetHelper(ERequestType.GET_ALLOTEMENTS).then(async (res) => {
          const today = new Date()
          today.setDate(-1)
          const todayString = today.getFullYear()+'-'+this.leadingZero(today.getMonth()+1)+'-'+this.leadingZero(today.getDate())
          const todayTs = formatToLocalDate(`${todayString}T23:59:59`).getTime()
          let tab = []

          for (let i = 0; i < res.entries.length; i++) {
            const el = res.entries[i]
            const end = formatToLocalDate(`${el.end}T23:59:59`).getTime()
            if (end < todayTs) {
              tab.push(el._id)
              await fetchPostHelper(ERequestType.DELETE_ALLOTEMENTS, {filter: {_id: el._id}}).catch(err => {
                console.error(err)
              })
            }
          }
        })
      },
      getEvents ({ start, end }) {
        if (Object.entries(this.estasData).length) {
          fetchGetHelper(ERequestType.GET_ALLOTEMENTS).then((res) => {
            const events = []

            // for each establishement
            Object.entries(this.estasData).forEach(([idesta, esta]) => {
              if (idesta === this.establishement || this.establishement === 'all') {
                let daysAllot = {}
                // get specific allotements by day
                res.entries.forEach(el => {
                  const min = formatToLocalDate(`${el.start}T00:00:00`)
                  const max = formatToLocalDate(`${el.end}T23:59:59`)
                  const days = (max.getTime() - min.getTime()) / 86400000

                  if (el.location._id === esta._id) {
                    for (let i = 0; i < Math.round(days); i++) {
                      let ts = min.getTime() + (86400000 * i)
                      let monthOffsetDiff = min.getTimezoneOffset() - new Date(ts).getTimezoneOffset()
                      if(min.getTimezoneOffset() !== new Date(ts).getTimezoneOffset()) {
                        ts = ts - (monthOffsetDiff * 60000)
                      }
                      
                      let priceClassic = el.priceClassic
                      if (el.priceClassic === undefined || el.priceClassic === '') priceClassic = 0
                      let priceAdventage = el.priceAdventage
                      if (el.priceAdventage === undefined || el.priceAdventage === '') priceAdventage = 0
                      let priceFamily = el.priceFamily
                      if (el.priceFamily === undefined || el.priceFamily === '') priceFamily = 0

                      let alltFamily = this.estasData[esta.id].defaultAllotementFamily
                      if (el.family) alltFamily = el.family

                      Vue.set(daysAllot, (ts).toString(), {
                        allotClassic: el.classic,
                        allotAdventage: el.adventage,
                        allotFamily: alltFamily,
                        priceClassic: priceClassic,
                        priceAdventage: priceAdventage,
                        priceFamily: priceFamily,
                        location: el.location,
                        soins: el.soins
                      })
                    }
                  }
                });

                // populate defaults allotements
                const min = new Date(`${start.date}T00:00:00`)
                const max = new Date(`${end.date}T23:59:59`)
                const days = (max.getTime() - min.getTime()) / 86400000

                for (let i = 0; i < Math.round(days); i++) {
                  let ts = min.getTime() + (86400000 * i)
                  let monthOffsetDiff = min.getTimezoneOffset() - new Date(ts).getTimezoneOffset()
                  if(min.getTimezoneOffset() !== new Date(ts).getTimezoneOffset()) {
                    ts = ts - (monthOffsetDiff * 60000)
                  }

                  // hack for horraire de d'hiver
                  let tsCalendar = new Date(ts).getTime()
                  // set current date
                  let current = formatToLocalDate(tsCalendar)
                  let currentYear = new Date(ts).getFullYear().toString()

                  // set default allotement
                  let dayAllot = this.defaultAllot[idesta]

                  // get seasons
                  let seasonYearTab = this.estasData[idesta].seasonality.filter(el => el.year.value === currentYear)[0].seasons
                  let foundedSeason = 'lowSeason'
                  Object.entries(seasonYearTab).forEach(season => {
                    season[1].value.forEach(dates => {
                      const start = new Date(`${this.convertDateSeasons(dates.value.start, currentYear)}T00:00:00`)
                      const end = new Date(`${this.convertDateSeasons(dates.value.end, currentYear)}T23:59:59`)
                      if (tsCalendar >= start && tsCalendar <= end) foundedSeason = season[0]
                    })
                  })
                  
                  // set specific allotement
                  if (daysAllot[ts]) dayAllot = daysAllot[ts]
                  // set default price
                  if(!daysAllot[ts] || parseInt(daysAllot[ts].priceClassic) === 0) {
                    dayAllot.priceClassic = this.defaultPriceAcco[idesta][foundedSeason]['classic']
                  }
                  if(!daysAllot[ts] || parseInt(daysAllot[ts].priceAdventage) === 0) {
                    dayAllot.priceAdventage = this.defaultPriceAcco[idesta][foundedSeason]['adventage']
                  }
                  if(!daysAllot[ts] || parseInt(daysAllot[ts].priceFamily) === 0 || daysAllot[ts].priceFamily === undefined) {
                    dayAllot.priceFamily = this.defaultPriceAcco[idesta][foundedSeason]['family']
                  }
                  if(!daysAllot[ts] || !dayAllot.soins) {
                    dayAllot.soins = false
                  }

                  // get total allotement
                  let total = parseInt(dayAllot.allotClassic) + parseInt(dayAllot.allotAdventage) + parseInt(dayAllot.allotFamily)
                  // get nbRoomBooked
                  let nbRoomBookedTotal = 0
                  let nbRoomBookedClassic = 0
                  let nbRoomBookedAdventage = 0
                  let nbRoomBookedFamily = 0
                  let dayOrders = []
                  if (this.orders[idesta] && this.orders[idesta][ts] && this.orders[idesta][ts].length > 0) {
                    this.orders[idesta][ts].forEach(order => {
                      nbRoomBookedTotal += parseInt(order.nbRooms)
                      if (order.roomType === 'classic') {
                        nbRoomBookedClassic += parseInt(order.nbRooms)
                      } else if(order.roomType === 'family') {
                        nbRoomBookedFamily += parseInt(order.nbRooms)
                      } else {
                        nbRoomBookedAdventage += parseInt(order.nbRooms)
                      }
                      let tmpOrder = order
                      // set name
                      tmpOrder.name = order.firstName.toString().toUpperCase()
                      // set room type
                      tmpOrder.rType = 'Standard'
                      if (order.roomType === 'adventage') tmpOrder.rType = 'Avantage'
                      if (order.roomType === 'family') tmpOrder.rType = 'Familiale'
                      // set pension type
                      tmpOrder.pensionType = ''
                      if (order.pension === 'half') tmpOrder.pensionType = 'Demi-pension'
                      if (order.pension === 'full') tmpOrder.pensionType = 'Pension complète'
                      if (order.pension === 'breakfast') tmpOrder.pensionType = 'Petit déjeuner'
                      // set startDate french format
                      let s = formatToLocalDate(order.startDate)
                      tmpOrder.start = this.leadingZero(s.getDate()) +' / '+ this.leadingZero((s.getMonth() + 1)) + ' / ' + s.getFullYear() 
                      // set endDate french format
                      let e = formatToLocalDate(order.endDate)
                      tmpOrder.end = this.leadingZero(e.getDate()) +' / '+ this.leadingZero((e.getMonth() + 1)) + ' / ' + e.getFullYear() 
                      // set paidAt french format
                      let p = new Date(order.paidAt)
                      tmpOrder.paidDate = this.leadingZero(p.getDate()) +' / '+ this.leadingZero((p.getMonth() + 1)) + ' / ' + p.getFullYear() + ' à ' + p.getHours() + 'H' + p.getMinutes()
                      // french care type
                      tmpOrder.cType = order.careType
                      if (order.careType === 'stays') tmpOrder.cType = 'escale'
                      // push order formated
                      dayOrders.push(tmpOrder)
                    })
                  }

                  // get state color
                  let colorState = 'green'
                  if (nbRoomBookedTotal >= (total/2)) colorState = 'orange'
                  if (nbRoomBookedTotal === total) colorState = 'red'

                  // passed day
                  const d = new Date();
                  d.setHours(0,0,0,0);
                  if (ts < d) colorState = 'grey'

                  // create event 
                  events.push({
                    name: esta.name + ' Dispos.',
                    start: current,
                    timed: false,
                    color: colorState,
                    datas: {
                      ts : tsCalendar,
                      date: current,
                      estaData: esta,
                      estaId: idesta,
                      allotClassic: dayAllot.allotClassic,
                      allotAdventage: dayAllot.allotAdventage,
                      allotFamily: dayAllot.allotFamily,
                      priceClassic: dayAllot.priceClassic,
                      soins: dayAllot.soins,
                      priceAdventage: dayAllot.priceAdventage,
                      priceFamily: dayAllot.priceFamily,
                      location: dayAllot.location,
                      nbRoomBookedClassic: nbRoomBookedClassic,
                      nbRoomBookedAdventage: nbRoomBookedAdventage,
                      nbRoomBookedFamily: nbRoomBookedFamily,
                      orders: dayOrders
                    }
                  })
                }
              }
            })
            this.events = events
          })
        }
      },
      refreshEvents () {
        if(this.$refs.calendar) {
          let tmp = {
            start: {
              date: this.$refs.calendar.$children[0].start
            },
            end: {
              date: this.$refs.calendar.$children[0].end
            }
          }
          this.getEvents(tmp)
        }
      },

      /**
       * Refresh orders
       */
      async refreshOrdersDatas () {
        // clean orders
        await fetchGetHelper(ERequestType.GET_ORDERS).then((res) => {
          this.orders = Object.assign({}, {})
          this.mainOrders = res.entries
          res.entries.forEach(order => {
            if (order.startDate && order.startDate !== "") {
              // for order by establishement
              if (!this.orders[order.establishmentId]) Vue.set(this.orders, order.establishmentId, {})
              // get alls nights
              const start = new Date(`${order.startDate}T00:00:00`)
              const end = new Date(`${order.endDate}T23:59:59`)
              const days = Math.round((end.getTime() - start.getTime()) / 86400000)
              // populate by ts
              for (let i = 0; i < days - 1; i++) {
                let ts = new Date(start.getTime() + (86400000 * i)).getTime()

                if (!this.orders[order.establishmentId][ts]) Vue.set(this.orders[order.establishmentId], ts, [])
                this.orders[order.establishmentId][ts].push(Object.assign({}, order))
              }
            }
          })
        })
      },

      /**
       * Refresh orders
       */
      async refreshIntentOrdersDatas () {
        // clean orders
        this.intentOrders = {}
        await fetchGetHelper(ERequestType.GET_INTENT_ORDERS).then((res) => {
          let intentNotValidated = []
          res.entries.forEach(order => {
            if (this.mainOrders.length > 0) {
              let found = this.mainOrders.find(el => el.idCT === order.idCT)
              let orderUpdated = order
              
              if (found) orderUpdated = found
              orderUpdated["validated"] = true

              
              if (!found && parseInt(order.total) > 0) {
                orderUpdated["validated"] = false
                
              }
              intentNotValidated.push(orderUpdated)
            }
          })
          this.intentOrders = intentNotValidated
        })
        this.loading = false

      },

      /**
       * refresh Establishements
       */
      async refreshEstablishementsDatas () {
        this.loading = true
        // get default allotement
        await fetchGetHelper(ERequestType.GET_ESTAS).then((res) => {
          res.entries.forEach(esta => {
            Vue.set(this.estasData, esta.id, esta)
            Vue.set(this.defaultAllot, esta.id, {
              allotClassic: esta.defaultAllotementClassic,
              allotAdventage: esta.defaultAllotementAdventage,
              allotFamily: esta.defaultAllotementFamily
            })
          })
        })
        // get default prices
        await fetchGetHelper(ERequestType.GET_ACCOMODATION).then((res) => {
          res.entries.forEach(entrie => {
            let idEsta = Object.entries(this.estasData).filter(esta => esta[1]._id === entrie.location[0]._id/* esta[1]._id === entrie.location[0]._id */)[0][0]
            Object.entries(entrie.pricing2[entrie.pricing2.length - 1].value.pricing).forEach(esta => {
              if(esta[0] !== 'multiple') {
                Vue.set(this.defaultPriceAcco, idEsta, {})
                Object.entries(esta[1]).forEach(season => {
                  Vue.set(this.defaultPriceAcco[idEsta], season[0], {})
                  Object.entries(season[1]).forEach(room => {
                    Vue.set(this.defaultPriceAcco[idEsta][season[0]], room[0], room[1].night.price.value)
                  })
                })
              }
            })
          })
        })
    
        // get orders
        await this.refreshOrdersDatas().then(() => {
          this.refreshIntentOrdersDatas()
          this.refreshEvents()

        })
        this.refreshEvents()
      },
      setSelectedEstablishement (selectEsta) {
        this.establishement = selectEsta
        this.refreshEvents()
      },
      onWindowFocusChange: function (e) {
        if (this.tab !== 'tab-3') {
          this.refreshEstablishementsDatas()
        }
      },
      detectFocusOut: function () {
        window.addEventListener('focus', this.onWindowFocusChange)
        window.addEventListener('pageshow', this.onWindowFocusChange)
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      // get establishments
      this.refreshEstablishementsDatas()
      this.detectFocusOut()
    },

    // -------------------------------------------- destroy
    destroyed: function () {
      window.removeEventListener('focus', this.onWindowFocusChange)
      window.removeEventListener('pageshow', this.onWindowFocusChange)
    }
  } 
</script>

<style lang="scss">
    @import './styles/reference';

    .v-calendar {
      .v-event-more{
        background-color: inherit !important;
        content: '';
        color: white;
      }
      .v-event {
        height: auto !important;
        display: block !important;
      }
    }

    .v-calendar-weekly__week{
      min-height: inherit;
    }
    .btn-centered{
      text-align: center;
      margin: 20px 0;
    }

    .loader{
      height: 100vh;
      width: 100vw;
      position: relative;
      &-content{
        text-align: center;
        position: absolute;
        top: 50%;
        width: 100%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: auto;
      }
    }

    // Component name
    $COMPONENT: 'App';
    .App {
        // --------------------------------------------------------------------- CONFIG

        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

