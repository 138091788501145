// imports
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    storesIsLoaded: false
  },
  getters: {
    getStoresIsLoaded: function (state) {
      return state.storesIsLoaded
    }
  },
  mutations: {
    setLoadedState: function (state, bool) {
      state.storesIsLoaded = bool
    }
  }
})
