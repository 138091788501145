import Vue from 'vue';
import Vuetify from 'vuetify/lib';
/* import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css' */

// Vuetify Object (as described in the Vuetify 2 documentation)
// const vuetify = new Vuetify()

Vue.use(Vuetify);

/* Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
}) */

import fr from 'vuetify/es5/locale/fr'

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr',
    },
});
